import instance from ".";

export async function create() {
  return await instance.get('/api/users/create');
}


export async function logIn(password) {
  return await instance.post('/api/users/login', {
    password : password
  }, {
    withCredentials: true
  });
}

export async function checkSession() {
  return await instance.get('/api/users/check');
}

export async function logOut() {
  return await instance.post('/api/users/logout', {}, {
    withCredentials: true
  });
}