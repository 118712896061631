import { nextTick } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import store from "./store";

const routes = [
    {
        path: "/",
        name: "home",
        meta: {title : 'SUN SYSTEM :: 전시기획 | 디자인전문기업 | 성공하는 전시디자인 썬시스템'},
        component: () => import('./views/HomePage')
    },
    {
        path: "/portfolio",
        name: "work",
        meta: {title : 'SUN SYSTEM :: 성공하는 전시디자인 썬시스템 :: Work'},
        component: () => import('./views/PortfolioPage')
    },
    {
        path: "/company",
        name: "company",
        meta: {title : 'SUN SYSTEM :: 성공하는 전시디자인 썬시스템 :: Company'},
        component: () => import('./views/CompanyPage')
    },
    {
        path: "/pr",
        name: "pr",
        meta: {title : 'SUN SYSTEM :: 성공하는 전시디자인 썬시스템 :: PR'},
        component: () => import('./views/PRPage')
    },
    {
        path: "/customer",
        name: "customer",
        meta: {title : 'SUN SYSTEM :: 성공하는 전시디자인 썬시스템 :: Customer'},
        component: () => import('./views/NoticeListPage.vue')
    },
    {
        path: "/customer/notice",
        name: "customer_notice",
        meta: {title : 'SUN SYSTEM :: 성공하는 전시디자인 썬시스템 :: Customer'},
        component: () => import('./views/NoticeListPage.vue')
    },
    {
        path: "/customer/notice/detail",
        name: "customer_notice_detail",
        meta: {title : 'SUN SYSTEM :: 성공하는 전시디자인 썬시스템 :: Customer'},
        component: () => import('./views/NoticeDetailPage.vue')
    },
    {
        path: "/customer/qna",
        name: "customer_qna",
        meta: {title : 'SUN SYSTEM :: 성공하는 전시디자인 썬시스템 :: Customer'},
        component: () => import('./views/QnaListPage.vue')
    },
    {
        path: "/customer/qna/detail",
        name: "customer_qna_detail",
        component: () => import('./views/QnaDetailPage.vue'),
        meta: {title : 'SUN SYSTEM :: 성공하는 전시디자인 썬시스템 :: Customer'},
    },
    {
        path: "/customer/faq",
        name: "customer_faq",
        component: () => import('./views/FaqListPage.vue'),
        meta: {title : 'SUN SYSTEM :: 성공하는 전시디자인 썬시스템 :: Customer'},
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import('./views/ContactPage'),
        meta: {title : 'SUN SYSTEM :: 성공하는 전시디자인 썬시스템 :: Contact'},
    },
    {
        path: "/admin",
        name: "admin_login",
        component: () => import('./views/AdminPage'),
        meta: {title : 'SUN SYSTEM :: Admin'},
        beforeEnter: async (to, from, next) => {
            await store.dispatch('checkUserState');

            if (store.getters.getUserState) {
            next('/admin/work');
            } else {
            next();
            }
        }
    },
    {
        path: "/admin/work",
        name: "admin_work",
        component: () => import('./views/AdminWorkPage'),
        meta: {title : 'SUN SYSTEM :: Admin :: Work'},
        beforeEnter: redirectIfNotAdmin
    },
    {
        path: "/admin/notice",
        name: "admin_notice",
        component: () => import('./views/AdminNoticePage'),
        meta: {title : 'SUN SYSTEM :: Admin :: Notice'},
        beforeEnter: redirectIfNotAdmin
    },
    {
        path: "/admin/faq/",
        name: "admin_faq",
        component: () => import('./views/AdminFaqPage'),
        meta: {title : 'SUN SYSTEM :: Admin :: FAQ'},
        beforeEnter: redirectIfNotAdmin
    },
    {
        path: "/:anything",
        name: "error",
        component: () => import('./views/ErrorPage'),
        meta: {title : 'SUN SYSTEM :: 전시기획 | 디자인전문기업 | 성공하는 전시디자인 썬시스템'},
    }
];


async function redirectIfNotAdmin(to, from, next) {
    await store.dispatch('checkUserState');
    if (!store.getters.getUserState) {
        next('/admin');
    } else {
        next();
    }
}


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


router.afterEach((to, from) => {
    const title = to.meta.title || from.meta.title || "SUN SYSTEM :: 성공하는 전시디자인 썬시스템";
    nextTick(() => {
        document.title = title;
    });
});

export default router;