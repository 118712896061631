<template>  
  <div class="hidden lg:flex w-full h-[220px] justify-center mb-0 bg-[#858585]">
    <div class="flex h-full w-full max-w-[1024px] items-center text-white">
      <div class="flex justify-start items-start">
        <img src="@/assets/images/bottom_logo.png" class="w-[115px]"/>
      </div>
      <div class="ml-[57px]">
        <div class="w-[180px] pb-[12px] border-b border-white">
          <p class="text-[12px]">CONTACT INFO</p>
        </div>
        <div class="mt-[12px]">
          <p class="text-[12px] mb-[3px]">T +82 2 557 7323</p>
          <p class="text-[12px] mb-[3px]">F +82 31 714 7324</p>
          <p class="text-[12px] mb-[3px]">E sunss7323@hanmail.net</p>
          <p class="text-[12px]">&nbsp;</p>
        </div>
      </div>      
      <div class="flex flex-col w-full ml-[43px]">
        <div class="w-full pb-[12px] border-b border-white">
          <p class="text-[12px]">OFFICES</p>
        </div>        
        <div class="mt-[12px] flex">
          <div class="w-1/2">
          <p class="text-[12px] mb-[3px]">13615</p>
          <p class="text-[12px] mb-[3px]">경기도 성남시 분당구 정자일로 6번길 13, #733</p>
          <p class="text-[12px] mb-[3px]">13 Jeongjail-ro 6beon-gil, Bundang-gu,</p>
          <p class="text-[12px]">Seongnam-si, Gyeonggi-do, Republic of Korea</p>
        </div>
          <div class="w-1/2">
          <p class="text-[12px] mb-[3px]">12963</p>
          <p class="text-[12px] mb-[3px]">경기도 하남시 신장로 33번길 36-20 (천현동)</p>
          <p class="text-[12px] mb-[3px]">36-20, Sinjang-ro 33beon-gil, Hanam-si</p>
          <p class="text-[12px]">Gyeonggi-do, Republic of Korea</p>
        </div>
        </div>
      </div>
    </div>
  </div>

  <!-- tab -->
  <div class="hidden md:flex lg:hidden w-full pt-14 pb-14 justify-center items-center mb-0  bg-[#858585]">
    <div class="flex w-[88.8%] flex-col">
      <div class="flex flex-row w-full">
        <div class="w-1/4">
          <img src="@/assets/images/bottom_logo.png" class="w-[115px]"/>
        </div>
        <div class="w-3/4 text-white">
            <div class="w-[180px] pb-[12px] border-b border-white">
              <p class="text-[12px]">CONTACT INFO</p>
            </div>
            <div class="mt-[12px]">
              <p class="text-[12px] mb-[3px]">T +82 2 557 7323</p>
              <p class="text-[12px] mb-[3px]">F +82 31 714 7324</p>
              <p class="text-[12px] mb-[3px]">E sunss7323@hanmail.net</p>
              <p class="text-[12px]">&nbsp;</p>
            </div>
        </div>        
      </div>
      <div class="flex flex-row w-full">
        <div class="w-1/4">&nbsp;</div>
        <div class="w-3/4 text-white">
          <div class="w-full pb-[12px] border-b border-white">
            <p class="text-[12px]">OFFICES</p>
          </div>        
          <div class="mt-[12px] flex">
            <div class="w-1/2">
              <p class="text-[12px] mb-[3px]">13615</p>
              <p class="text-[12px] mb-[3px]">경기도 성남시 분당구 정자일로 6번길 13, #733</p>
              <p class="text-[12px] mb-[3px]">13 Jeongjail-ro 6beon-gil, Bundang-gu,</p>
              <p class="text-[12px]">Seongnam-si, Gyeonggi-do, Republic of Korea</p>
            </div>
            <div class="w-1/2">
              <p class="text-[12px] mb-[3px]">12963</p>
              <p class="text-[12px] mb-[3px]">경기도 하남시 신장로 33번길 36-20 (천현동)</p>
              <p class="text-[12px] mb-[3px]">36-20, Sinjang-ro 33beon-gil, Hanam-si</p>
              <p class="text-[12px]">Gyeonggi-do, Republic of Korea</p>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </div>
  <!-- mobile -->
  <div class="md:hidden flex w-full pt-[25px] pb-[25px] justify-center items-center mb-0  bg-[#858585]">
    <div class="flex w-[88.8%] flex-col">
      <div class="flex flex-row w-full mb-[5px]">
        <div class="w-1/6 pt-[7px]">
          <img src="@/assets/images/bottom_logo.png" class="w-[55px]"/>
        </div>
        <div class="w-5/6 text-white scale-90">
            <div class="w-[160px] pb-[7px] border-b border-white">
              <p class="text-[11px]">CONTACT INFO</p>
            </div>
            <div class="mt-[7px]">
              <p class="text-[10px] mb-[3px]">T +82 2 557 7323</p>
              <p class="text-[10px] mb-[3px]">F +82 31 714 7324</p>
              <p class="text-[10px] mb-[3px]">E sunss7323@hanmail.net</p>
              <p class="text-[10px]">&nbsp;</p>
            </div>
        </div>        
      </div>
      <div class="flex flex-row w-full">
        <div class="w-1/6">&nbsp;</div>
        <div class="w-5/6 text-white scale-90">
          <div class="w-full pb-[7px] border-b border-white">
            <p class="text-[11px]">OFFICES</p>
          </div>        
          <div class="mt-[7px] flex">
            <div class="w-[47%]">
              <p class="text-[10px] mb-[3px]">13615</p>
              <p class="text-[10px] mb-[3px]">경기도 성남시 분당구 정자일로</p>
              <p class="text-[10px] mb-[3px]">6번길 13, #733</p>
              <p class="text-[10px] mb-[3px]">13 Jeongjail-ro 6beon-gil,</p>
              <p class="text-[10px] mb-[3px]">Bundang-gu, Seongnam-si,</p>
              <p class="text-[10px]">Gyeonggi-do, Republic of Korea</p>
            </div>
            <div class="w-[6%]"></div>
            <div class="w-[47%]">
              <p class="text-[10px] mb-[3px]">12963</p>
              <p class="text-[10px] mb-[3px]">경기도 하남시 신장로 33번길</p>
              <p class="text-[10px] mb-[3px]">36-20 (천현동)</p>
              <p class="text-[10px] mb-[3px]">36-20, Sinjang-ro 33beon-gil,</p>
              <p class="text-[10px] mb-[3px]">Hanam-si, Gyeonggi-do,</p>
              <p class="text-[10px]">Republic of Korea</p>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </div>


  <div class="flex w-full justify-center items-center mb-[20px] mt-[30px] text-[#ACACAC] font-light text-xs md:text-sm">
    Copyright 2023. SUNSystem All Right Reserved.
  </div>

</template>

<script>
export default {
  name: 'footerBar'
}
</script>

<style>

</style>