<template>
  <headerBar/>
  <router-view></router-view>
  <footerBar />
</template>

<script>
import headerBar from './layouts/headerBar.vue'
import footerBar from './layouts/footerBar.vue'
import "@/assets/fonts/fonts.css"

export default {
  name: 'App',
  components: {
    headerBar,
    footerBar
  }
}
</script>

<style>
#app {
  font-family: 'NotoSansKR-VariableFont_wght', sans-serif !important;
}
</style>
