<template>
  <div class="hidden lg:flex">
    <div class="flex w-full h-[60px] justify-center mb-0 border-b border-[#ededed]">
      <div class="grid grid-cols-12 gap-3 h-full w-full max-w-[1024px] items-center">
        <div class="col-start-1 col-span-5"><a href="/"><img src="@/assets/images/top_logo.png" /></a></div>
        <div class="col-start-6 col-span-7">
          <div class="flex flex-row w-full justify-end">
            <div v-for="item, i in menu" :key="i" class="ml-[60px] text-right text-[13px] text-[#303030] font-semibold hover:underline">
              <a :href="item.href">{{ item.name }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- mobile -->
  <div class="lg:hidden flex">
    <div class="flex w-full h-[55px] justify-center mb-0 border-b border-[#ededed]">
      <div class="grid grid-cols-6 gap-4 h-full w-[88.8%] w-min-[320px] items-center">
        <div class="col-start-1 col-span-5">
          <a href="/"><img src="@/assets/images/top_logo.png" /></a>
        </div>
        <div class="col-start-6 col-span-1">
          <div class="flex justify-end">
            <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 focus:outline-none"  @click="mobileMenuOpen = true">
              <span class="sr-only">Open main menu</span>
              <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-50" />
      <DialogPanel class="fixed inset-y-0 right-0 z-50 w-1/2 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-end">
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a v-for="item in menu" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </div>

</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'

const mobileMenuOpen = ref(false);
const menu = [
  {name : 'COMPANY', href: '/company'},
  {name : 'WORK', href: '/portfolio'},
  {name : 'REQUEST', href: '/pr'},
  {name : 'CUSTOMER', href: '/customer'},
  {name : 'CONTACT', href: '/contact'},
];

</script>

<style>

</style>