// local vue api axios instance
import axios from 'axios';
import store from '@/store';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials : true
});

const checkPaths = [
  '/api/faq/create',
  '/api/faq/update',
  '/api/faq/delete',
  '/api/notice/create',
  '/api/notice/update',
  '/api/notice/delete',
  '/api/notice/fileUpload',
  '/api/notice/fileDelete',
  '/api/portfolios/create',
  '/api/portfolios/edit',
  '/api/portfolios/delete'
];

const checkPatterns = [
  /\/api\/faq\/delete\/\d+$/,
  /\/api\/notice\/delete\/\d+$/,
  /\/api\/portfolios\/delete\/\d+$/
];

instance.interceptors.request.use(async (config) => {
  const url = config.url.split('?')[0];

  // 체크할 API 경로나 동적 경로 패턴에 대해서만 상태 확인
  if (checkPaths.includes(url) || checkPatterns.some(pattern => pattern.test(url))) {
    console.log('check login session');
    await store.dispatch('checkUserState');
  }
  return config;

}, (error) => {
  console.log('need to check login session');
  return Promise.reject(error);
});

export default instance;
