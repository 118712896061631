import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import stores from './store.js'

import './style.css'

const app = createApp(App);
app.config.globalProperties.$store = stores;
app.use(router);
app.use(stores);
app.mount('#app')
