import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import * as userApi from '@/api/users'

const store = createStore ({
  state: {
    isAdmin: false,
    lastChecked: null
  },
  getters: {
    getUserState(state){
        return state.isAdmin;
    },
    shouldCheckState(state) {
      if (!state.lastChecked) return true;
      const now = Date.now();
      const elapsed = now - state.lastChecked;

      // 5분 미만으로 지난 경우 재확인하지 않음
      return elapsed > 5 * 60 * 1000;  
    }
  },
  actions: {
    async checkUserState({ commit, state, getters }) {
      console.log(state.isAdmin, state.lastChecked);

      if (getters.shouldCheckState) {
        try {
          const res = await userApi.checkSession();
          commit('setUserState', res.data.isAdmin);
          commit('setLastChecked');  // 상태 확인 후 현재 시간을 저장
        } catch (err) {
          console.log(err);
        }
      }
    },
  },

  mutations: { // [변수들을 조작하는 함수들]
    setUserState(state, check){
        state.isAdmin = check;
    },
    setLastChecked(state) {
      state.lastChecked = Date.now();
    },
  },

  plugins:[createPersistedState({
    paths :["isAdmin", "lastChecked"]
  })]
});

export default store;